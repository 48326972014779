[data-lesson-description] {
  [data-content] {
    @apply prose relative max-w-none pt-5 prose-headings:font-heading prose-headings:font-black prose-p:first-of-type:mt-0 prose-code:text-[90%] xl:pt-8 2xl:pt-5;
  }
  [data-content-visible='false'] {
    @apply after:absolute after:bottom-0 after:left-0 after:h-1/2 after:w-full after:bg-gradient-to-b after:from-transparent after:via-gray-50 after:to-gray-50 after:content-[""] sm:after:via-gray-100 sm:after:to-gray-100 2xl:after:via-gray-50 2xl:after:to-gray-50;
  }
  div[role='status'] {
    @apply flex w-full -translate-y-16 animate-pulse flex-col gap-3;
    div:nth-child(1) {
      @apply h-3 w-2/3 rounded-full bg-gray-200;
    }
    div:nth-child(2) {
      @apply h-3 rounded-full bg-gray-200;
    }
    div:nth-child(3) {
      @apply h-3 w-1/2 rounded-full bg-gray-200;
    }
    div:nth-child(4) {
      @apply h-3 w-5/6 rounded-full bg-gray-200;
    }
    div:nth-child(5) {
      @apply h-3 w-2/5 rounded-full bg-gray-200;
    }
    div:nth-child(6) {
      @apply h-3 w-1/3 rounded-full bg-gray-200;
    }
  }
  [data-cta] {
    @apply relative flex -translate-y-8 items-center justify-center rounded-lg bg-white p-5 shadow-2xl shadow-gray-400/40 before:absolute before:top-[-8px] before:h-4 before:w-4 before:rotate-45 before:bg-white before:content-[''];
    p {
      @apply prose max-w-none text-gray-800 prose-a:text-brand-red;
    }
  }
}
