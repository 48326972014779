/* Purchase detail page reset */

#purchase-detail {
  [data-invite-team] {
    /* reset styles because it is rendered within a card */
    @apply flex w-full flex-col rounded-none border-none bg-transparent p-0 py-3 shadow-none;
  }
}

/* Shared form component */

[data-invite-team] {
  @apply flex flex-col rounded-lg border border-gray-100 bg-white px-5 py-6 shadow-xl shadow-gray-400/5;
  [data-title] {
    @apply pt-1;
    /* 3 seats left */
    strong {
      @apply font-semibold;
    }
  }
  [data-copy-invite-link-container] {
    @apply w-full;
    [data-copy-invite-link] {
      /* optional */
    }
  }
  [data-redeem] {
    @apply mt-5 flex flex-col gap-3 border-t border-gray-100 pt-5 sm:mt-7 sm:flex-row sm:items-center sm:justify-between;
    [data-title] {
      /* Or get access yourself */
      @apply flex items-center gap-1 font-semibold;
    }
    [data-self-redeem-button] {
      /* optional */
    }
  }
}

/* Card */

[data-team-card] {
  @apply w-full rounded-lg border border-gray-800/80 bg-black/60 p-5 text-white;
}

[data-team-card] > [data-content] {
  @apply pt-3;
}

[data-team-card] > [data-title] {
  @apply flex items-center gap-3 text-xl font-semibold;
  svg {
    @apply w-5 text-green-500;
  }
}

/* Self redeem button */

[data-self-redeem-button] {
  @apply rounded-full bg-brand-red px-6 py-3 text-base font-semibold text-white transition hover:brightness-125;
}
[data-self-redeem-button][disabled] {
  @apply cursor-not-allowed opacity-30;
}

/* Buy more seats form */

[data-buy-more-seats-form] {
  #team-upgrade-pricing-inline {
    @apply flex w-full flex-col justify-between gap-5;
    [data-seats-form] {
      @apply inline-flex items-center justify-center gap-1 sm:gap-3;
      label {
        /* Seats */
        @apply pr-2 font-medium opacity-80 sm:pr-0;
      }
      input {
        @apply rounded-md border border-gray-200 bg-gray-100 py-2 pl-3 font-mono font-bold;
      }
      button {
        @apply flex h-full items-center justify-center rounded bg-gray-200/60 px-3 py-2 font-mono sm:hidden;
      }
    }
    [data-pricing-product] {
      [data-pricing-product-header] {
        @apply flex items-center gap-5;
      }
      button[type='submit'] {
        @apply rounded-lg bg-blue-500 px-5 py-3 font-medium text-white transition hover:bg-blue-600;
      }
    }
  }
}

/* Copy invite link form */
[data-copy-invite-link] {
  label {
    /* Invite share link */
    @apply sr-only;
  }
  div {
    @apply flex gap-3 pt-2;
  }
  input {
    @apply w-full rounded-md border border-gray-200 bg-gray-100 px-3 py-2 text-sm font-semibold text-black transition selection:bg-sky-500 selection:text-white hover:bg-gray-200;
  }
  input[disabled] {
    @apply opacity-50;
  }
  [data-sr-button] {
    @apply flex flex-shrink-0 items-center gap-1 rounded-full bg-sky-400/20 px-5 py-2 text-sm font-semibold text-sky-600 transition hover:bg-sky-400/30;
  }
  [data-sr-button][disabled] {
    @apply cursor-not-allowed opacity-30;
  }
}
