@tailwind base;
@tailwind components;
@import '@code-hike/mdx/dist/index.css';
@import './subscribe-form.css';
@import './quiz.css';
@import './prism.css';
@import './focus-visible.css';
@import './nprogress.css';
@import './commerce.css';
@import './portable-text.css';
@import '../purchase-details/purchase-details.css';
@import '@skillrecordings/feedback-widget/dist/styles.css';
@import './team.css';
@import './redeem-dialog.css';
@import './login.css';

@import './video/module-lesson-list.css';
@import './video/video-overlays.css';
@import './video/lesson-description.css';
@import './video/lesson-title.css';
@import './video/video-transcript.css';
@import './video/github-link.css';

@tailwind utilities;

/* code-hike code blocks */
.ch-code {
  @apply bg-gray-900 !important;
}
.ch-codeblock {
  @apply shadow-none;
}

html,
body,
#root {
  color-scheme: light;
}

@layer utilities {
  .no-marker {
    ::-webkit-details-marker {
      display: none;
    }
  }
}

mux-player {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  @apply relative overflow-hidden;
}

mux-player::part(vertical-layer) {
  background-color: rgba(0, 0, 0, 0);
}
