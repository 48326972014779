h1[data-lesson-title] {
  @apply pb-5 pt-3 font-heading text-3xl font-black tracking-tight sm:text-4xl sm:leading-tight xl:text-[2.65rem] 2xl:text-4xl;
}

[data-lesson-badge] {
  @apply inline-block rounded-full px-2.5 py-1 font-mono text-xs font-semibold uppercase sm:mt-5 2xl:mt-0;
}
[data-lesson-badge='solution'] {
  @apply bg-teal-500/20 text-teal-600;
}
[data-lesson-badge='exercise'] {
  @apply bg-brand-red/20 text-brand-red;
}
[data-lesson-badge='explainer'] {
  @apply bg-indigo-500/20 text-indigo-600;
}
[data-lesson-badge='interview'] {
  @apply bg-sky-500/20 text-sky-600;
}
