#__next {
  [data-pricing-container] {
    @apply mx-auto flex w-full max-w-screen-lg flex-wrap justify-center gap-5;
    #main-pricing {
      @apply w-full max-w-sm;
      [data-pricing-product] {
        [data-pricing-product-image] {
          @apply relative mx-auto -mb-32 h-56 w-56;
        }
        article {
          @apply rounded-lg border border-gray-100 bg-white pt-36 shadow-2xl shadow-gray-500/10;
        }
        [data-pricing-product-header] {
          [data-byline] {
            @apply hidden;
          }
          [data-name-badge] {
            @apply pb-2 text-center font-heading text-sm font-extrabold uppercase text-brand-red;
          }
          [data-title] {
            @apply text-center font-heading text-3xl font-black;
          }
          [data-instructor] {
            @apply flex items-center justify-center gap-2 pt-3;
            [data-instructor-image] {
              @apply flex h-11 w-11 items-center justify-center overflow-hidden rounded-full;
            }
            span {
              @apply text-base font-semibold text-gray-800;
            }
          }
          [data-price-container] {
            @apply flex items-center justify-center gap-0.5 pt-6 font-heading;
            sup {
              @apply -translate-y-1 pr-1 align-sub text-base font-black text-gray-500;
            }
            [data-price] {
              @apply flex text-[2.65rem] font-black;
            }
            span {
              @apply translate-y-2.5 pl-0.5 align-super text-gray-800;
            }
            [data-price-discounted] {
              @apply flex flex-col items-center pl-3 pt-2.5 font-sans text-lg font-semibold;

              [data-full-price] {
                @apply relative flex text-xl leading-none text-gray-600 before:absolute before:left-0 before:top-1/2 before:h-0.5 before:w-full before:bg-gray-700/80;

                span {
                  @apply translate-y-0.5 text-xs leading-none text-gray-500;
                }
              }
              [data-percent-off] {
                @apply text-base text-pink-500;
              }
            }
          }
          [data-price-container='loading'] {
            @apply flex items-center justify-center;
          }
          [data-loading-price] {
            @apply flex h-16 items-center justify-center;
          }
          [data-price-container='error'] {
            @apply hidden;
          }
          [data-pricing-product-sale-countdown] {
            @apply mt-5 flex w-full flex-col rounded-lg border border-gray-200 py-5 text-center;
            [data-grid] {
              @apply mx-auto grid max-w-[300px] grid-cols-4 items-center justify-center gap-3 tabular-nums tracking-tight;
            }
            [data-title] {
              @apply pb-4 text-base font-medium;
            }
            [data-number] {
              @apply font-heading text-3xl font-black leading-none;
            }
            [data-label] {
              @apply pt-1 text-xs font-semibold uppercase tracking-wide opacity-75;
            }
          }
        }

        [data-purchased-container] {
          @apply px-5 pt-6;
          [data-loading-price] {
            @apply flex h-12 items-center justify-center py-6;
          }
          button[type='submit'] {
            @apply flex w-full items-center justify-center rounded-md bg-blue-600 px-4 py-4 text-center font-semibold text-white shadow-lg ring-offset-1 transition ease-in-out focus-visible:ring-blue-600 disabled:cursor-wait hover:bg-blue-700 hover:shadow-gray-600/20;

            span {
              @apply relative z-10;
            }
          }
          label {
            @apply flex items-center justify-center;
          }
          [data-pricing-product-header] {
            @apply justify-center;
          }
          [data-purchased] {
            @apply mb-5 flex items-center justify-center gap-1 border-b border-gray-100 pb-5 font-heading text-lg font-black;
            svg {
              @apply h-8 w-8 text-sky-500;
            }
          }
          [data-unavailable] {
            @apply py-4 text-center font-heading text-lg font-bold text-blue-500;
          }
          [data-price-container] {
            @apply pt-0;
          }
          #team-upgrade-pricing-inline {
            @apply flex flex-col justify-center;
            [data-pricing-product-header] {
              @apply flex w-full flex-col justify-center;
              [data-price] {
                @apply text-3xl;
              }
              button {
                @apply border-2 border-blue-500 bg-transparent text-blue-600 shadow-none hover:border-blue-600 hover:bg-blue-600 hover:text-white;
              }
            }
          }
        }
        [data-purchase-container] {
          @apply px-5 pt-6;
          form {
            fieldset {
              [data-guarantee] {
                @apply block pt-3 text-center text-xs text-gray-600;
              }
              legend {
              }
              [data-team-switch] {
                @apply flex items-center justify-center gap-2 pb-3.5 text-sm;
                label {
                  @apply sr-only;
                }
                button[role='button'] {
                  @apply text-gray-700 decoration-gray-600 underline-offset-2 transition hover:underline;
                }
                button[role='switch'] {
                  @apply relative h-6 w-[47px] rounded-full border border-gray-300/50 bg-gray-200 shadow-md shadow-gray-300/30 transition hover:bg-gray-300/50 radix-state-checked:bg-gray-200 hover:radix-state-checked:bg-gray-300/50;
                  span {
                    @apply block h-[18px] w-[18px] translate-x-[2px] rounded-full bg-gray-500 shadow-sm shadow-gray-300/50 transition-all will-change-transform group-hover:bg-gray-300 radix-state-checked:translate-x-[25px] radix-state-checked:bg-blue-500 group-hover:radix-state-checked:bg-indigo-400;
                  }
                }
              }
              [data-quantity-input] {
                @apply mb-5 flex w-full flex-col items-center justify-center px-5 xl:px-12;
                div {
                  @apply flex items-center gap-1 text-sm font-medium;
                  label {
                    @apply mr-3 opacity-80;
                  }
                  input {
                    @apply max-w-[70px] rounded-md border border-gray-200 bg-gray-200/60 py-2 pl-3 font-mono font-bold ring-blue-500;
                  }
                  button {
                    @apply flex h-full items-center justify-center rounded bg-gray-200/60 px-3 py-2 font-mono sm:hidden;
                  }
                }
              }
            }
            [data-pricing-product-checkout-button] {
              @apply flex w-full items-center justify-center rounded-md bg-blue-600 px-4 py-4 text-center font-semibold text-white shadow-lg ring-offset-1 transition ease-in-out focus-visible:ring-blue-600 disabled:cursor-wait hover:bg-blue-700 hover:shadow-gray-600/20;

              span {
                @apply relative z-10;
              }
            }

            [data-pricing-product-checkout-button]:disabled {
              @apply cursor-wait;
            }
          }
        }

        [data-ppp-container] {
          @apply px-5 pt-6 text-sm;
          [data-ppp-header] {
            strong {
              img {
                @apply inline-block;
              }
            }
            p {
              @apply pt-3;
            }
          }
          label {
            @apply mt-5 flex cursor-pointer items-center gap-2 rounded-md bg-gray-100 p-3 transition hover:bg-gray-200/60;
            input {
            }
            span {
              @apply font-semibold;
            }
          }
        }

        [data-pricing-footer] {
          [data-header] {
            @apply hidden;
          }
          [data-guarantee-image] {
            @apply hidden;
          }
          [data-main] {
            @apply mt-7 border-t border-gray-100  pt-5 text-base;
            strong {
              @apply hidden;
            }
            ul {
              @apply px-5;
            }
            li {
              @apply relative py-1 pl-6 before:absolute before:left-0 before:text-emerald-600 before:content-['✓'];
            }
            [data-contents] {
              @apply relative mx-[1px] mb-[1px] mt-3 flex w-full items-center justify-between rounded-b-lg bg-gray-50 px-5 py-3 text-sm font-medium text-gray-700;
              span {
                @apply absolute;
              }
              a {
                @apply pr-4;
                span {
                  @apply pl-1 transition;
                }
              }
              a:hover {
                span {
                  @apply translate-x-1 transition;
                }
              }
            }
          }
        }
      }
    }
  }

  [data-workshop] {
    /* ⭐️ To target specific workshop: [data-workshop="workshop-slug"] */
    [data-pricing-product] {
      [data-pricing-product-image] {
      }
      article {
      }
      [data-pricing-product-header] {
        [data-byline] {
          @apply hidden;
        }
        [data-name-badge] {
        }
        [data-price-container] {
          @apply flex items-center justify-center gap-0.5 font-heading;
          sup {
            @apply pt-1.5 align-sub text-base font-black text-gray-500;
          }
          [data-price] {
            @apply flex text-5xl font-black;
          }
          span {
            @apply pl-0.5 pt-0.5 align-super text-gray-800;
          }
          [data-price-discounted] {
            @apply flex flex-col items-center pl-3 font-sans text-lg font-semibold;

            [data-full-price] {
              @apply relative flex text-xl leading-none text-gray-600 before:absolute before:left-0 before:top-1/2 before:h-0.5 before:w-full before:bg-gray-700/80;

              span {
                @apply text-xs leading-none text-gray-500;
              }
            }
            [data-percent-off] {
              @apply text-base text-pink-500;
            }
          }
        }
        [data-price-container='loading'] {
          @apply flex items-center py-2;
        }
        [data-price-container='error'] {
          @apply hidden;
        }
        [data-pricing-product-sale-countdown] {
          @apply mt-5 flex w-full flex-col rounded-lg border border-gray-200 px-3 py-5 text-center;
          [data-grid] {
            @apply mx-auto grid max-w-[300px] grid-cols-4 items-center justify-center gap-3 tabular-nums tracking-tight;
          }
          [data-title] {
            @apply pb-4 text-sm font-medium sm:text-base;
          }
          [data-number] {
            @apply font-heading text-2xl font-black leading-none sm:text-3xl;
          }
          [data-label] {
            @apply pt-1 text-xs font-semibold uppercase tracking-wide opacity-75;
          }
        }
      }
      [data-purchased-container] {
        [data-purchased] {
          @apply mb-5 flex items-center gap-1 border-b border-gray-100 pb-5 font-heading text-lg font-black;
          svg {
            @apply h-8 w-8 text-sky-500;
          }
        }
        [data-unavailable] {
          @apply py-4 text-center font-heading text-lg font-bold text-blue-500;
        }
        #team-upgrade-pricing-inline {
          [data-pricing-product-header] {
            [data-price] {
              @apply text-3xl;
            }
          }
        }
      }
      [data-purchase-container] {
        @apply pt-8;
        form {
          fieldset {
            [data-guarantee] {
              @apply block pt-3 text-center text-xs text-gray-600;
            }
            legend {
            }
            [data-team-switch] {
              @apply flex items-center justify-center gap-2 pb-3.5 text-sm;
              label {
                @apply sr-only;
              }
              button[role='button'] {
                @apply text-gray-700 decoration-gray-600 underline-offset-2 transition hover:underline;
              }
              button[role='switch'] {
                @apply relative h-6 w-[47px] rounded-full border border-gray-300/50 bg-gray-200 shadow-md shadow-gray-300/30 transition hover:bg-gray-300/50 radix-state-checked:bg-gray-200 hover:radix-state-checked:bg-gray-300/50;
                span {
                  @apply block h-[18px] w-[18px] translate-x-[2px] rounded-full bg-gray-500 shadow-sm shadow-gray-300/50 transition-all will-change-transform group-hover:bg-gray-300 radix-state-checked:translate-x-[25px] radix-state-checked:bg-blue-500 group-hover:radix-state-checked:bg-indigo-400;
                }
              }
            }
            [data-quantity-input] {
              @apply mb-5 flex w-full flex-col items-center justify-center px-5 xl:px-12;
              div {
                @apply flex items-center gap-1 text-sm font-medium;
                label {
                  @apply mr-3 opacity-80;
                }
                input {
                  @apply max-w-[70px] rounded-md border border-gray-200 bg-gray-200/60 py-2 pl-3 font-mono font-bold ring-blue-500;
                }
                button {
                  @apply flex h-full items-center justify-center rounded bg-gray-200/60 px-3 py-2 font-mono sm:hidden;
                }
              }
            }
          }
          [data-pricing-product-checkout-button] {
            @apply flex w-full items-center justify-center rounded-md bg-blue-600 px-4 py-4 text-center font-semibold text-white shadow-lg ring-offset-1 transition ease-in-out focus-visible:ring-blue-600 disabled:cursor-wait hover:bg-blue-700 hover:shadow-gray-600/20;

            span {
              @apply relative z-10;
            }
          }

          [data-pricing-product-checkout-button]:disabled {
            @apply cursor-wait;
          }
        }
      }

      [data-ppp-container] {
        @apply pt-5 text-sm;
        [data-ppp-header] {
          strong {
            img {
              @apply inline-block;
            }
          }
          p {
            @apply pt-3;
          }
        }
        label {
          @apply mt-5 flex cursor-pointer items-center gap-2 rounded-md bg-gray-100 p-3 transition hover:bg-gray-200/60;
          input {
          }
          span {
            @apply font-semibold;
          }
        }
      }

      [data-guarantee] {
        @apply hidden;
        /* @apply flex justify-center pt-8 align-middle; */
      }

      [data-pricing-footer] {
        @apply hidden;
      }
    }
  }

  #video-overlay {
    [data-pricing-product] {
      @apply min-h-[280px] w-full max-w-[350px];
      [data-pricing-product-image] {
      }
      article {
        /* @apply rounded-lg border border-gray-200/40 bg-white p-7 shadow-2xl shadow-gray-400/20; */
      }

      [data-pricing-product-header] {
        [data-byline] {
          @apply hidden;
        }
        [data-name-badge] {
        }
        [data-price-container] {
          @apply flex items-center justify-center gap-0.5 font-heading;
          sup {
            @apply -translate-y-2 align-sub text-base font-black text-gray-300;
          }
          [data-price] {
            @apply flex gap-0.5 text-5xl font-black;
          }
          span {
            @apply pl-0.5 pt-0.5 align-super text-gray-200;
          }

          [data-price-discounted] {
            @apply flex flex-col items-center pl-5 font-semibold;

            [data-full-price] {
              @apply relative flex text-2xl text-gray-200 before:absolute before:left-0 before:top-1/2 before:h-0.5 before:w-full before:bg-gray-200/80;

              span {
                @apply text-sm;
              }
            }
            [data-percent-off] {
              @apply text-base text-pink-300;
            }
          }
        }
        [data-pricing-product-sale-countdown] {
          @apply mt-5 flex w-full flex-col rounded-lg border-2 border-white/10 px-5 py-5 text-center;
          [data-grid] {
            @apply mx-auto grid grid-cols-4 items-center justify-center gap-3 tabular-nums tracking-tight sm:max-w-[300px];
          }
          [data-title] {
            @apply pb-4 text-sm font-medium sm:text-base;
          }
          [data-number] {
            @apply font-heading text-2xl font-black leading-none sm:text-3xl;
          }
          [data-label] {
            @apply pt-1 text-xs font-semibold uppercase tracking-wide opacity-75;
          }
        }
      }
      [data-price-container='loading'] {
        @apply flex items-center py-3;
        svg {
          @apply text-white;
        }
      }
      [data-purchased-container] {
        [data-purchased] {
          @apply flex items-center justify-center gap-1;
          svg {
            @apply h-8 w-8;
          }
        }
        [data-unavailable] {
          @apply py-4 text-center font-heading text-lg font-bold text-blue-400;
        }
      }
      [data-purchase-container] {
        @apply pt-8;
        form {
          fieldset {
            [data-guarantee] {
              @apply block pt-3 text-center text-xs text-gray-200;
            }
            legend {
            }
            [data-team-switch] {
              @apply flex items-center justify-center gap-2 pb-3.5 text-sm;
              label {
                @apply sr-only;
              }
              button[role='button'] {
                @apply text-gray-200 decoration-gray-600 underline-offset-2 transition hover:underline;
              }
              button[role='switch'] {
                @apply relative h-6 w-[47px] rounded-full border border-gray-300/50 bg-gray-800 shadow-md shadow-black/30 transition hover:bg-gray-700 radix-state-checked:bg-gray-800 hover:radix-state-checked:bg-gray-700;
                span {
                  @apply block h-[18px] w-[18px] translate-x-[2px] rounded-full bg-gray-300 shadow-sm shadow-black/50 transition-all will-change-transform group-hover:bg-gray-300 radix-state-checked:translate-x-[25px] radix-state-checked:bg-blue-400 group-hover:radix-state-checked:bg-blue-300;
                }
              }
            }
            [data-quantity-input] {
              @apply mb-5 flex w-full flex-col items-center justify-center px-5 xl:px-12;
              div {
                @apply flex items-center gap-1 text-sm font-medium;
                label {
                  @apply mr-3;
                }
                input {
                  @apply max-w-[70px] rounded-md border border-gray-600 bg-gray-800 py-2 pl-3 font-mono font-bold ring-blue-500;
                }
                button {
                  @apply flex h-full items-center justify-center rounded bg-gray-700/50 px-3 py-2 font-mono sm:hidden;
                }
              }
            }
          }
          [data-pricing-product-checkout-button] {
            @apply flex w-full items-center justify-center rounded-md bg-blue-600 px-4 py-4 text-center font-semibold text-white shadow-lg ring-offset-1 transition ease-in-out focus-visible:ring-blue-600 disabled:cursor-wait hover:bg-blue-700 hover:shadow-gray-600/20;

            span {
              @apply relative z-10;
            }
          }

          [data-pricing-product-checkout-button]:disabled {
            @apply cursor-wait;
          }
        }
      }
      [data-ppp-container] {
        @apply mt-8 rounded-md bg-gray-800 p-5 text-left text-sm;
        [data-ppp-header] {
          strong {
            img {
              @apply inline-block;
            }
          }
          p {
            @apply pt-3;
          }
        }
        label {
          @apply mt-5 flex cursor-pointer items-center gap-2 rounded-md bg-gray-700 p-3 transition hover:bg-gray-600/80;
          input {
          }
          span {
            @apply font-semibold;
          }
        }
      }
      [data-sr-convertkit-subscribe-form] {
        @apply flex w-full flex-col gap-2 text-left;
        [data-sr-input-wrapper] {
          @apply w-full;
        }
        [data-sr-input] {
          @apply w-full rounded-md border border-gray-600 bg-gray-700/60 px-3 py-3 text-base text-white placeholder:text-white/80 focus-visible:ring-blue-500;
        }

        [data-sr-input-label] {
          @apply sr-only;
        }
        [data-sr-button] {
          @apply relative flex flex-shrink-0 items-center justify-center rounded-lg bg-blue-500 px-5 py-3 font-semibold text-white shadow-xl shadow-blue-800/20 ring-offset-1 transition focus-visible:ring-blue-500 hover:brightness-110;
          svg {
            @apply h-7 w-7;
          }
        }
        [data-sr-button][disabled] {
        }
      }
      [data-sr-convertkit-subscribe-form='success'] {
        p {
          @apply py-2 text-center;
        }
      }
      [data-sr-convertkit-subscribe-form='error'] {
        p {
          @apply py-2 text-center;
        }
      }
      [data-guarantee] {
        @apply hidden;
        /* @apply flex justify-center pt-8 align-middle; */
      }

      [data-pricing-footer] {
        @apply hidden;
      }
    }
  }
}

#buy-more-seats-modal {
  #team-upgrade-pricing-inline {
    @apply flex flex-col items-center pt-5;
    label {
      span {
      }
      input {
        @apply selection:bg-sky-500 focus-visible:ring-sky-500;
      }
    }
    [data-pricing-product-header] {
      [data-price-container='loading'] {
        @apply py-6;
      }
      [data-price-container] {
        [data-price] {
          @apply text-center;
          sup {
            @apply pr-1 align-sub;
          }
          @apply font-heading text-3xl font-black;
          span {
            @apply align-super text-sm;
          }
        }

        [data-price-discounted] {
          @apply mt-3 flex items-center gap-5 rounded-lg bg-amber-100 px-3 py-1 text-lg text-amber-600;
          [data-full-price] {
            @apply text-2xl line-through;
            span {
              @apply align-super text-xs;
            }
          }
          [data-percent-off] {
            @apply font-extrabold;
          }
        }
      }
    }
    button {
      @apply mt-3 w-full rounded-full bg-sky-500 hover:bg-sky-600;
    }
  }
}
