/* Redeem dialog */
[data-redeem-dialog-content] {
  @apply fixed left-1/2 top-1/2 z-50 w-full max-w-[95%] -translate-x-1/2 -translate-y-1/2 rounded-md bg-white shadow-xl sm:max-w-md;
  [data-title] {
    @apply px-8 pt-8 text-xl font-bold;
  }
  [data-description] {
    @apply border-b border-gray-100 px-8 pb-8 pt-4 text-gray-600;
  }
  form {
    @apply px-8 py-4;
    [data-email] {
      @apply mt-2 flex flex-col;
      label {
        @apply pb-1;
      }
      input {
        @apply rounded-md border border-gray-200 bg-gray-100 px-4 py-2 focus-visible:border-transparent;
      }
    }
    [data-actions] {
      @apply flex w-full justify-end gap-3 py-8;
      [data-cancel] {
        @apply flex rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-black transition;
      }
      [data-cancel]:hover {
        @apply bg-gray-300;
      }
      [data-submit] {
        @apply flex rounded-md border border-sky-600/50 bg-sky-500 px-4 py-2 text-sm font-medium text-white shadow-sm transition focus:outline-none;
      }
      [data-submit]:hover {
        @apply bg-sky-600;
      }
    }
  }
}
[data-redeem-dialog-overlay] {
  @apply fixed inset-0 z-40 bg-black bg-opacity-30 backdrop-blur-sm;
}
