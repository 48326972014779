#purchase-detail {
  #purchase-transfer {
    [data-transfer-state] {
      @apply flex flex-col gap-3;
      p {
        @apply font-medium text-gray-700;
      }
      button {
        @apply py-2.5 text-sm shadow-none;
      }
    }
    [data-transfer-state='AVAILABLE'] {
      h2 {
        @apply hidden;
      }
      p:last-of-type {
        @apply font-semibold;
      }
    }
    [data-transfer-state='INITIATED'] {
      h2 {
        @apply text-base font-medium text-gray-700 before:mr-1 before:animate-pulse before:rounded-full before:px-1 before:text-xl before:content-["📧"];
      }
      button {
        @apply self-start bg-gray-300 text-black;
      }
    }
    [data-transfer-state='COMPLETED'] {
      p:first-of-type {
        @apply before:mr-1 before:px-1 before:text-xl before:leading-none before:text-emerald-600 before:content-["✓"];
      }
    }
  }

  #purchase-transfer-form {
    label {
    }
    input {
    }
    span {
    }
    button {
    }
  }
}

#welcome {
  #purchase-transfer {
    [data-transfer-state] {
      @apply flex flex-col gap-3 rounded-lg border border-gray-100 bg-white px-5 py-6 shadow-xl shadow-gray-400/5;
      p {
        @apply font-medium text-gray-700;
      }
      button {
        @apply py-2.5 text-sm shadow-none;
      }
    }
    [data-transfer-state='AVAILABLE'] {
      h2 {
        @apply hidden;
      }
      p:last-of-type {
        @apply font-semibold;
      }
    }
    [data-transfer-state='INITIATED'] {
      h2 {
        @apply text-base font-medium text-gray-700 before:mr-1 before:animate-pulse before:rounded-full before:px-1 before:text-xl before:content-["📧"];
      }
      button {
        @apply self-start bg-gray-300 text-black;
      }
    }
    [data-transfer-state='COMPLETED'] {
      p:first-of-type {
        @apply before:mr-1 before:px-1 before:text-xl before:leading-none before:text-emerald-600 before:content-["✓"];
      }
    }
  }
}
