/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  @apply outline-none;
}

/*
  Define a strong focus indicator for keyboard focus. If you choose to skip this 
  step then the browser's default focus indicator will be displayed instead.
*/
.js-focus-visible .focus-visible {
  @apply outline-none ring-2 ring-brand-red;
}

.monaco-mouse-cursor-text {
  @apply outline-none ring-0 focus:outline-none !important;
}
