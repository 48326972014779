/* module lesson list */
[data-module-lesson-list-header] {
  @apply relative z-50 hidden w-full flex-shrink-0 lg:block lg:max-w-[280px] xl:max-w-xs;
  [data-sticky-container] {
    @apply top-0 border-r border-gray-200/60 shadow-2xl shadow-gray-300/40 lg:sticky;
    [data-content-wrapper] {
      @apply top-0 z-10 border-t border-gray-100 lg:sticky;
      [data-content] {
        @apply relative flex items-center gap-5 bg-white px-3 py-1 shadow-lg shadow-gray-300/40;
        [data-image] {
          @apply relative z-10;
        }
        [data-title] {
          @apply relative z-10 -translate-y-0.5;
          [data-type] {
            @apply font-mono text-xs font-semibold uppercase text-gray-500 hover:underline;
          }
          span {
            @apply pl-1 text-xs text-gray-300;
          }
          h2 {
            @apply w-full font-heading text-xl font-extrabold leading-none;
            a {
              @apply hover:underline;
            }
          }
        }
      }
    }
    [data-fadeout] {
      @apply pointer-events-none absolute bottom-0 left-0 z-20 h-24 w-full bg-gradient-to-t from-gray-50 to-transparent;
    }
  }
}
[data-mobile-module-lesson-list] {
  @apply block lg:hidden;
  ::-webkit-details-marker {
    display: none;
  }
  summary {
    @apply flex cursor-pointer items-center gap-1 bg-white px-4 py-3 font-medium shadow-lg shadow-gray-300/30 transition marker:content-[''] after:absolute after:right-3 after:flex after:h-6 after:w-6 after:rotate-180 after:items-center after:justify-center after:rounded-full after:bg-gray-100 after:text-lg after:content-['↑']  hover:bg-gray-50;
    [data-byline] {
      @apply text-sm opacity-80;
    }
  }
  [data-module-lesson-list-header] {
    @apply relative z-50 block w-full flex-shrink-0 border-r border-gray-800 lg:hidden lg:max-w-[280px] xl:max-w-xs;
  }
}
[data-module-lesson-list] {
  @apply relative h-[400px] overflow-y-auto pb-16 scrollbar-thin scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 lg:h-[calc(100vh-90px)];
  nav {
    @apply pb-3;
  }
  /* sections */
  /* [data-sections] {
    @apply relative;
    [data-accordion-header] {
      @apply sticky top-0 z-10 overflow-hidden bg-gray-900 shadow-xl shadow-black/20;
      [data-accordion-trigger] {
        @apply relative z-10 flex w-full items-center justify-between border-b border-white/5 bg-gray-800/20 py-3 pl-3 pr-4 text-left text-lg font-semibold leading-tight transition hover:bg-gray-800/40;
        [data-icons] {
          @apply flex items-center;
        }
        [data-check-icon] {
          @apply mr-2 h-4 w-4 text-teal-400;
        }
        [data-chevron-down-icon] {
          @apply relative h-3 w-3 opacity-50 transition group-radix-state-open:rotate-180;
        }
        [data-active-section-indicator] {
          @apply mr-3 h-1 w-1 animate-pulse rounded-full bg-cyan-500 opacity-75 duration-1000;
        }
      }
      [data-accordion-trigger][data-state='open'] {
        [data-chevron-down-icon] {
          @apply rotate-180 opacity-80;
        }
      }
      [data-accordion-trigger]:hover {
        [data-chevron-down-icon] {
          @apply opacity-100;
        }
      }
      [data-section-progress] {
        @apply absolute left-0 top-0 h-full bg-white/5;
      }
      [data-accordion-content] {
        ul {
          @apply flex flex-col divide-y divide-gray-800/0 border-b border-white/5 text-lg;
        }
      }
    }
  } */
  /* single section */
  [data-single-section] {
    @apply flex flex-col divide-y divide-gray-800/0 text-lg text-gray-700;
  }
  /* lesson */
  [data-lesson][data-is-lesson-completed='true'][data-is-expanded='false'] {
    @apply text-gray-700 opacity-80 transition hover:text-gray-900 hover:opacity-100;
  }
  [data-lesson][data-is-expanded='false'] {
    @apply opacity-90 hover:opacity-100;
    [data-lesson-title] {
      @apply hover:bg-white;
    }
  }
  [data-lesson][data-is-expanded='true'] {
    @apply bg-gray-200/60;
    [data-lesson-title] {
      @apply hover:bg-gray-200/60;
    }
  }
  [data-lesson] {
    [data-lesson-title] {
      @apply relative flex items-center px-8 py-3 text-base font-semibold leading-tight;
      [data-check-icon] {
        @apply absolute left-2 w-4 text-emerald-500;
      }
      [data-index] {
        @apply absolute left-3 text-xs opacity-50;
      }
    }
    ul {
      @apply text-gray-700;
      /* problem */
      [data-problem] {
        a {
          @apply flex items-center border-l-4 py-2.5 px-8 text-sm font-medium transition;
        }
        a[data-is-active='true'] {
          @apply border-brand-red bg-white shadow-lg shadow-gray-300/20;
        }
        a[data-is-active='false'] {
          @apply border-transparent hover:bg-gray-200;
        }
      }
      /* exercise */
      [data-exercise-is-loading] {
        @apply flex items-center border-l-4 py-2.5 px-8 text-sm font-medium transition;
      }
      [data-exercise] {
        a {
          @apply flex items-center border-l-4 py-2.5 px-8 text-sm font-medium transition;
        }
        a[data-is-active='true'] {
          @apply border-brand-gray-600 bg-white shadow-lg shadow-gray-300/20;
        }
        a[data-is-active='false'] {
          @apply border-transparent hover:bg-gray-200;
        }
      }
      /* solution */
      [data-solution] {
        a {
          @apply flex items-center border-l-4 py-2.5 px-8 text-sm font-medium transition;
        }
        a[data-is-active='true'] {
          @apply border-teal-500 bg-white shadow-lg shadow-gray-300/20;
        }
        a[data-is-active='false'] {
          @apply border-transparent hover:bg-gray-200;
        }
      }
      /* explainer */
      [data-explainer] {
        a {
          @apply flex items-center border-l-4 py-2.5 px-8 text-sm font-medium transition;
        }
        a[data-is-active='true'] {
          @apply border-indigo-500 bg-white shadow-lg shadow-gray-300/20;
        }
        a[data-is-active='false'] {
          @apply border-transparent hover:bg-gray-200;
        }
      }
    }
  }
  /* section resources */
  [data-section-resources] {
    @apply bg-gray-200 pt-1 pb-8;
    [data-label] {
      @apply px-5 pt-4 pb-2 text-xs font-medium uppercase tracking-wide text-gray-600;
    }
    ul {
      @apply flex flex-col divide-y divide-gray-300/0 text-lg;
      li {
        @apply pt-2;
        a {
          @apply flex items-center px-4 py-2 font-semibold leading-tight hover:bg-gray-200;
          svg {
            @apply mr-3 h-3 w-3 flex-shrink-0 text-gray-500;
          }
        }
        p {
          @apply pl-10 pr-3 text-sm italic text-gray-500;
        }
      }
    }
  }
}
