[data-github-link][role='status'] {
  @apply flex w-full items-center justify-center;
}
[data-github-link] {
  [data-content] {
    @apply flex items-center gap-2;
    a {
      @apply inline-flex items-center gap-2 rounded-lg bg-gray-800 px-4 py-2 text-lg font-medium text-white transition hover:bg-gray-900;
      svg {
        @apply h-6 w-6 flex-shrink-0;
      }
      [data-repo] {
        @apply font-semibold;
      }
    }
  }
}
